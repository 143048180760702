
import { defineComponent, ref, watch } from "vue";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "ModalExcellURLList",
  props: {
    toggle: {
      type: Boolean,
      default: false
    },
    URLs: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    // Variables
    let auxModal
    const excellURLList = ref<unknown[] | null>(null) 

    // Functions
    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    }

    function hanldeExtractFilenameFromURL(url: string): string {
      const parts = url.split('/');
      return parts[parts.length - 1];
    }

    // Life Cycles
    watch(() => props.toggle, () => {
      excellURLList.value = props.URLs
      openModal("ModalExcellURLList")
    })

    return {
      excellURLList,
      hanldeExtractFilenameFromURL
    }
  }
})
