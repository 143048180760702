
  import axios, { AxiosError } from 'axios';
  import { defineComponent, ref, onMounted, watch, onBeforeMount } from 'vue';
  import useAlert from "@/composables/Alert";
  import {
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampEmptyListFeedbackV2
  } from '@/components';
  import { useLoaderStore } from "@/store/LoaderStore";
  import { useFilterMainStore } from '@/store/FilterMainStore';
  import { useCompanyStore } from '@/store/CompanyStore';
  import { useStoreStore } from '@/store/StoreStore';
  import { useRemittanceStore } from '@/store/RemittanceStore';
  import ModalExcellURLList from "./Components/ModalExcellURLList.vue";
  import moment from 'moment';
  import { campHandleCPF } from '@/composables/DataValidation';
  
  interface IRemittanceDataFetch {
    id: number;
    cpf: string;
    name: string;
    cnpj: string;
    fantasy_name: string;
    corporate_name: string;
    quantity: number;
    value: number;
    status: string;
    time: string;
    reward: string;
    media: string;
  }
  
  interface IRemittancePrint extends IRemittanceDataFetch {
    checked: boolean;
    value: number;
  }
  
  interface IRemittanceResponse {
    data: {
      data: IRemittanceDataFetch[]
      error: boolean,
      message: string,
      errorCode: string
    }
  }
  
  interface IRemittanceListToPost {
    rescue_id: number,
    cpf: string,
    name: string,
    pdv: string,
    points: number,
    value: string
  }
  
  export interface IExcellURL { 
    url: string
  }
  
  export default defineComponent({
    name: "RemittanceRewardList",
    components: {
      CampHeader,
      CampTable,
      CampTableTd,
      CampTableTh,
      ModalExcellURLList,
      CampEmptyListFeedbackV2
    },
    setup() {
      const { showTimeAlert } = useAlert()
      const loaderStore = useLoaderStore()
      const filterMainStore = useFilterMainStore()
      // Company
      const companyStore = useCompanyStore()
      // Store
      const storeStore = useStoreStore()
      // Remittance
      const areAllSelected = ref(false)
      const isLoading = ref(false)
      const remittanceStore = useRemittanceStore()
      const remittanceList = ref<IRemittancePrint[] | null>(null)
      const remittanceListRender = ref<IRemittancePrint[] | null>(null)
      const keywordSearch = ref("")
      const remittanceListToPost = ref<any[] | null>(null)
      const excellURLList = ref<IExcellURL[] | null>(null)
      const toggleModalExcellURLList = ref(false)
      const togglePayment = ref(<any>[])
  
      // Functions
      /* Fetch */
      async function getRemittanceList() {
        if(!companyStore.getId) {
          return true
        }
        areAllSelected.value = false
        keywordSearch.value = ""
        try {
          isLoading.value = true
          let url = `/api/getRewardRescueList?id_company=${companyStore.getId}`
        //   let params = "?"
        //   if(storeStore.getId) {
        //     params += `id_store=${storeStore.getId}&`
        //   }
  
        //   if(remittanceStore.getStartDate && remittanceStore.getEndDate) {
        //     params += `start_date=${moment(remittanceStore.getStartDate).format('DD-MM-YYYY')}&end_date=${moment(remittanceStore.getEndDate).format('DD-MM-YYYY')}&`
        //   }
          
        //   if(params !== "?") {
        //     url += params.slice(0, -1)
        //   }
          const response:IRemittanceResponse = await axios.get(url)
          const { data } = response.data
          remittanceList.value = data.map(el => ({ ...el, checked: false }))
          remittanceListRender.value = [ ...remittanceList.value ]
          togglePayment.value = remittanceListRender.value.map(e => e.status === 'pago')
        } catch (error) {
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
          isLoading.value = false
        }
        return false
      }
  
      async function postRemittance(): Promise<boolean> {
        if(!remittanceListRender.value || !companyStore.getId) {
          return true
        }
        remittanceListToPost.value = remittanceListRender.value.filter((el: any) => el.checked).map((el: any) => {
            return {
                idRescueRewards: el.id,
            }
        })
        if (!remittanceListToPost.value || Array.isArray(remittanceListToPost.value) && !remittanceListToPost.value.length) {
            showTimeAlert("Nenhum prêmio aguardando aprovação foi selecionado", "error")
            return true
        }
        try {
        loaderStore.open()
        console.log(remittanceListToPost.value[0].idRescueRewards)
        const idRescueRewards: any = []
        remittanceListToPost.value.forEach(el => {
            idRescueRewards.push(el.idRescueRewards)
        })
          const result = await axios.post(`/api/approvedRescueReward`, idRescueRewards)
        //   excellURLList.value = result.data.data
        //   toggleModalExcellURLList.value = !toggleModalExcellURLList.value
        showTimeAlert("Aprovação realizada com sucesso!")
          areAllSelected.value = false
          getRemittanceList()
        } catch (error) {
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
        }
        return false
      }
  
      function handleFilterObjectsByKeyword(): boolean {
        areAllSelected.value = false
        if(!remittanceList.value)
          return true
        remittanceListRender.value = remittanceList.value.map(el => ({ ...el, checked: false })).filter((item) => {
          for (const key in item) {
            if (key !== 'checked') {
              const propValue = item[key];
              if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
                return true;
              }
              if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
                return true;
              }
            }
          }
          return false;
        });
        return false
      }
  
      function handleAllCheckbox() {
        if(!remittanceList.value)
          return true
          remittanceListRender.value = areAllSelected.value ? remittanceList.value.map
            (el => {
                if(el.status == 'pendente aprovacao') {
                    return (
                        {
                            ...el, checked: true 
                        }
                    )
                }else {
                    return (
                        {
                            ...el, checked: false 
                        }
                    )
                }
            }) 
            : remittanceList.value.map(el => ({ ...el, checked: false }))
        return false
      }
  
      function handleFormatInMonentaryValue(value: number) {
        return value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      }
  
      /* Local Storage */
      function getDataLocalStorage() {
        const resetCompany = ():void => {
          companyStore.setId(null)
          companyStore.setFantasyName("")
        }
        try {
          const cs = localStorage.getItem("cs") ? JSON.parse(localStorage.getItem("cs")!) : null
          if(cs && cs.i) {
            companyStore.setId(cs.i)
            companyStore.setFantasyName(cs.n)
          } else {
            resetCompany()
          }
        } catch (error) {
          resetCompany()
        }
  
        const resetStore = (): void => {
          storeStore.setId(null)
          storeStore.setFantasyName("")
        }
        try {
          const ss = localStorage.getItem("ss") ? JSON.parse(localStorage.getItem("ss")!) : null
          if(ss && ss.i) {
            storeStore.setId(ss.i)
            storeStore.setFantasyName(ss.n)
          } else {
            resetStore()
          }
        } catch (error) {
          resetStore()
        }
  
        try {
          const dr = localStorage.getItem("dr")
          if(dr) {
            remittanceStore.setDateRange(dr.split(","))
          } else {
            remittanceStore.setDateRange("")
          }
        } catch (error) {
          remittanceStore.setDateRange("")
        }
      }
  
      /* Handle Filter Modal */
      function handleInitFilterModal() {
        if(!companyStore.getId) {
          filterMainStore.hideModal()
          filterMainStore.showModal()
        }
      }

      async function paymentRescueReward(el: any, action: boolean) {
      if(el) {
        try {
          loaderStore.open()
          let url = action ? `/api/paymentRescueReward` : `/api/cancelpaymentRescueReward`
          let body = {
            'idRescueReward': el.id
          }
          await axios.post(url, body)
          await getRemittanceList()
      } catch (error) {
          if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
         } else {
            showTimeAlert("Algo deu errado!", "error")
          }
      } finally {
          loaderStore.close()
          // isLoading.value = false
      }
      }
    }
  
      // Life Cycles
      onBeforeMount(() => getDataLocalStorage())
  
      onMounted(() => {
        getRemittanceList()
        handleInitFilterModal()
      })
  
      watch(() => { companyStore.getId, storeStore.getId }, () => isLoading.value = true, { deep: true })
  
      watch(() => { companyStore.company, storeStore.store, remittanceStore.getStartDate, remittanceStore.getEndDate }, () => getRemittanceList(), { deep: true })
  
      watch(() => areAllSelected.value, () => handleAllCheckbox())
  
      watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())
  
      return {
        areAllSelected,
        isLoading,
        keywordSearch,
        companyStore,
        remittanceList,
        remittanceListRender,
        handleFilterObjectsByKeyword,
        postRemittance,
        toggleModalExcellURLList,
        excellURLList,
        handleFormatInMonentaryValue,
        moment,
        remittanceStore,
        campHandleCPF,
        togglePayment,
        paymentRescueReward
      }
    }
  })
  